@import url(https://fonts.googleapis.com/css2?family=Do+Hyeon&display=swap);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif; }

.underline {
  background-size: 85% 0.15em;
  background-position: 50% 100%; }

.hero h1 {
  font-size: 2.25em;
  font-weight: bold; }

.hero-mouse {
  position: relative;
  bottom: -75vh; }

main {
  overflow-x: hidden;
  overflow-y: hidden;
  max-width: 100vw; }

/* Navigation + Layout */
*:hover {
  text-decoration: none !important;
  text-decoration: none !important;
  text-decoration: none; }

.type-writer-inline {
  font-weight: 600;
  line-height: 4vh; }

.highlight {
  color: #01bf71; }

/*  --> About <--  */
.fontawesome {
  color: #101522;
  font-size: 5em; }

/* Contact */
#contact .Col {
  margin: 5% auto; }

#contact h2 {
  font-size: 2.25em;
  font-weight: bold;
  margin: 2vh auto;
  margin-bottom: 2.5%; }

#contact p {
  font-weight: 500;
  padding: 5%;
  padding-bottom: 0;
  font-size: 1.65em; }

/* MAP */
#map {
  border: groove #101522 2.5px;
  border-radius: 5%;
  max-width: 100vw;
  width: 100%;
  margin-top: 5%; }

.bottomSub {
  padding: 5%;
  font-size: .65em;
  font-style: italic;
  margin-top: 10%; }

.dangerToast {
  background-color: #d9534f;
  color: #fff;
  font-size: 1.25em; }

.dangerToast button {
  border: solid green 2px; }

@media only screen and (min-width: 45em) {
  .hero h1 {
    font-size: 3.75em;
    font-weight: bold; }

  .card {
    margin-top: 15%;
    min-height: 35vh; }

  .card ol li {
    margin: 2% auto;
    padding: 5%;
    text-align: start;
    width: 100%; }

  .card ol {
    margin: 15% auto;
    width: 100%; }

  .column {
    margin: 5% auto; }

  .bottom {
    justify-content: center;
    align-content: center;
    margin: 2% auto;
    margin-top: 15%; }

  .bottom button {
    justify-content: center;
    align-content: center;
    margin: 2% auto; }

  .bottom {
    font-size: 2em;
    margin: 10% auto; }

  /*  --> About <--  */
  /* --> Contact  <-- */
  #contact .Col {
    width: 35vw; }

  #contact h2 {
    font-size: 3em;
    text-transform: capitalize; }

  #contact p {
    font-size: 1.55em; }

  /* MAP */
  #map {
    margin-top: 10%; } }
@media only screen and (min-width: 64.25em) {
  /* Packages */
  .underline {
    background-size: 75% 0.15em;
    background-position: 0 98%; }

  .hero h1 {
    font-size: 3.85em;
    font-weight: bold; }

  .packages-Headers {
    padding: 1%; }

  .packages-Headers h2 {
    font-size: 3em; }

  .packages-Headers p {
    margin-top: 5%;
    font-size: 1.85em;
    font-weight: normal;
    font-weight: initial; }

  .card {
    background-color: #fff;
    color: black;
    min-height: 12vh;
    margin-top: 15%;
    padding: 2%;
    margin-bottom: 0; }

  .card img {
    margin: 0;
    padding: 0; }

  .card ol {
    margin: 15% auto;
    width: 120%;
    overflow: hidden;
    margin-bottom: 1%; }

  .card ol li {
    margin: 0;
    text-align: start; }

  .card .card-title {
    font-size: 1.2em;
    padding: 5%; }

  .card h3 {
    font-size: 1.1em; }

  .card p {
    font-size: 1.2em; }

  .bottom .button {
    margin: 3% auto; }

  .bottomSub {
    margin: 0 auto;
    padding: 0;
    padding-top: 5%;
    width: 75%;
    font-size: .75em; }

  /*  --> About <--  */
  /* MAP */
  /* --> Contact  <-- */
  #contact .Col {
    width: 35vw; }

  #contact h2 {
    margin-bottom: 3%; }

  #contact p {
    font-size: 1.55em; } }

/*# sourceMappingURL=Styles.css.map */

class name .ContactForm 

#contact {
    padding-top: 2%;
}

.ContactForm * {
    transition: 0.2s ease-in-out;
}

.ContactForm {
    margin: 5% auto;
}

.ContactForm form {
    background: #101522;
    margin: 10% auto;
    padding: 5%;
    border-radius: 1%;
    color:white;
    padding-bottom: 1%;
    width:100%;
    /*box shadow*/
    box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;
}

.ContactForm label {
    font-weight: bold;
    margin: 5% auto;
    font-size: 1.6em;
}

.ContactForm input, textarea {
    margin: 1% auto;
    width:75%;
}

.ContactForm textarea {
    width: 75%;
}

.ContactForm .submit-btn {
    margin: 5% auto;
    width:65%;
}

.ContactForm .submit-btn:hover {
    color:#101522;
}

.errorMessage {
    color: red;
    font-weight: bold;
    margin: 5% auto;
    font-size: 1.2em;
    font-style: italic;
}

.ContactForm  button {
    border-radius: 50px;
    background: #01bf71;
    white-space: nowrap;
    padding: 14px 48px;
    color: black;
    font-size: 20px;
    outline:none;
    border:none;
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration:none;
    font-weight: bold;
  
}
button:hover {
    color: #fff;
   
}

.ContactForm select {
    width: 75%;
    margin: 1% auto;
    text-align: center;

}

@media only screen and (min-width:45em) {
    /*md*/ 
    .ContactForm * {
        transition: 0.2s ease-in-out;
    }
    
    .ContactForm {
        margin: 5% auto;
     
    }
    
    .ContactForm form {
        background: #101522;
        margin: 10% auto;
        padding: 5%;
        color:white;
        border-radius: 5%;
    }
    
    .ContactForm input, textarea {
        margin: 1% auto;
        width:75%;
    }
    
    .ContactForm textarea {
        width: 75%;
    }
    
    .ContactForm .submit-btn {
        margin: 5% auto;
        width:65%;
    }
    
    .ContactForm .submit-btn:hover {
        color:#101522;
    }
}


@media only screen and (min-width:64.25em) {
    /*lg*/ 
    .ContactForm * {
        transition: 0.2s ease-in-out;
    }
    
    .ContactForm {
        margin: 2% auto;
     
    }
    
    .ContactForm form {
        background: #101522;
        margin: 3% auto;
        padding: 2%;
        color:white;
        border-radius: 5%;
        width: 90%;

        padding-bottom: 1%;    
    }
    
    .ContactForm input, textarea {
        margin: 1% auto;
        width:75%;
    }
    
    .ContactForm textarea {
        width: 75%;

    }

    .ContactForm select {
        width: 75%;
        margin: 1% auto;
        text-align: center;

    }
    
    .ContactForm .submit-btn {
        margin: 5% auto;
        width:65%;
    }
    
    .ContactForm .submit-btn:hover {
        color:#101522;
    }

    .ContactForm label {
        font-size: 1.2em;
        margin:2% auto;
    }


    #contact-form {
        padding:2.5%;
    }
}












