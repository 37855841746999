class name .ContactForm 

#contact {
    padding-top: 2%;
}

.ContactForm * {
    transition: 0.2s ease-in-out;
}

.ContactForm {
    margin: 5% auto;
}

.ContactForm form {
    background: #101522;
    margin: 10% auto;
    padding: 5%;
    border-radius: 1%;
    color:white;
    padding-bottom: 1%;
    width:100%;
    /*box shadow*/
    box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;
}

.ContactForm label {
    font-weight: bold;
    margin: 5% auto;
    font-size: 1.6em;
}

.ContactForm input, textarea {
    margin: 1% auto;
    width:75%;
}

.ContactForm textarea {
    width: 75%;
}

.ContactForm .submit-btn {
    margin: 5% auto;
    width:65%;
}

.ContactForm .submit-btn:hover {
    color:#101522;
}

.errorMessage {
    color: red;
    font-weight: bold;
    margin: 5% auto;
    font-size: 1.2em;
    font-style: italic;
}

.ContactForm  button {
    border-radius: 50px;
    background: #01bf71;
    white-space: nowrap;
    padding: 14px 48px;
    color: black;
    font-size: 20px;
    outline:none;
    border:none;
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration:none;
    font-weight: bold;
  
}
button:hover {
    color: #fff;
   
}

.ContactForm select {
    width: 75%;
    margin: 1% auto;
    text-align: center;

}

@media only screen and (min-width:45em) {
    /*md*/ 
    .ContactForm * {
        transition: 0.2s ease-in-out;
    }
    
    .ContactForm {
        margin: 5% auto;
     
    }
    
    .ContactForm form {
        background: #101522;
        margin: 10% auto;
        padding: 5%;
        color:white;
        border-radius: 5%;
    }
    
    .ContactForm input, textarea {
        margin: 1% auto;
        width:75%;
    }
    
    .ContactForm textarea {
        width: 75%;
    }
    
    .ContactForm .submit-btn {
        margin: 5% auto;
        width:65%;
    }
    
    .ContactForm .submit-btn:hover {
        color:#101522;
    }
}


@media only screen and (min-width:64.25em) {
    /*lg*/ 
    .ContactForm * {
        transition: 0.2s ease-in-out;
    }
    
    .ContactForm {
        margin: 2% auto;
     
    }
    
    .ContactForm form {
        background: #101522;
        margin: 3% auto;
        padding: 2%;
        color:white;
        border-radius: 5%;
        width: 90%;

        padding-bottom: 1%;    
    }
    
    .ContactForm input, textarea {
        margin: 1% auto;
        width:75%;
    }
    
    .ContactForm textarea {
        width: 75%;

    }

    .ContactForm select {
        width: 75%;
        margin: 1% auto;
        text-align: center;

    }
    
    .ContactForm .submit-btn {
        margin: 5% auto;
        width:65%;
    }
    
    .ContactForm .submit-btn:hover {
        color:#101522;
    }

    .ContactForm label {
        font-size: 1.2em;
        margin:2% auto;
    }


    #contact-form {
        padding:2.5%;
    }
}
